/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import config from '../../data/SiteConfig';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
// import './home.css';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const styles = {
  h1: {
    color: 'white',
    fontWeight: 300,
    fontFamily: 'Raleway,sans-serif',
    fontSize: 32,
    lineHeight: 1,
    letterSpacing: 2,
    textAlign: 'center',
  },
  h3: {
    color: 'white',
    fontWeight: 500,
    fontFamily: 'Raleway,sans-serif',
    fontSize: 22,
    lineHeight: 1.4,
    letterSpacing: 0,
    textAlign: 'center',
  },
};

class Home extends Component {
  handleChange(e) {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => alert('thanks!'))
      .catch((error) => alert(error));
  }

  render() {
    return (
      <div style={{ alignItems: 'center', justifyContent: 'center' }}>
        <Header config={config} />
        <div style={{ maxWidth: 980, margin: 'auto' }}>
          <div
            style={{
              marginTop: 60,
              backgroundImage: 'linear-gradient(180deg,#80adce,#0056ad)',
              paddingTop: 180,
              paddingBottom: 160,
              padding: 50,
            }}
          >
            <h1 style={styles.h1}>Parsed</h1>
            <h1 style={styles.h1}>Web and Mobile App Development</h1>
            <div
              style={{
                width: 100,
                height: 1,
                backgroundColor: 'white',
                margin: 'auto',
                marginTop: 50,
                marginBottom: 50,
              }}
            />
            <h3 style={styles.h3}>
              We help our customers to launch cross platform applications. We are a web and mobile app development
              company specializing in React and React Native.
            </h3>
          </div>
          <div style={{ padding: 50 }} id="contact">
            <h3 style={{ ...styles.h3, color: 'black' }}>Tell us about your project, and how we can help.</h3>

            <form
              name="contact"
              method="post"
              action="/thanks/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={(e) => this.handleSubmit(e)}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="contact" />
              <p hidden>
                <label>
                  Don’t fill this out: <input name="bot-field" onChange={(e) => this.handleChange(e)} />
                </label>
              </p>
              <div className="form-group">
                <label htmlFor="name">Your Name:</label>
                <input type="text" name="name" className="form-control" onChange={(e) => this.handleChange(e)} />
              </div>
              <p>
                <label>Your email:</label>
                <input type="email" name="email" className="form-control" onChange={(e) => this.handleChange(e)} />
              </p>
              <p>
                <label>Message:</label>
                <textarea name="message" onChange={(e) => this.handleChange(e)} className="form-control" />
              </p>
              <p>
                <button className="btn btn-primary" type="submit" style={{ backgroundColor: '#0056ad' }}>
                  Send
                </button>
              </p>
            </form>
          </div>
          {/* <div className="fl-page-content" itemProp="mainContentOfPage">
            <div className="fl-content-full container">
              <div className="row">
                <div className="fl-content col-md-12">
                  <article
                    className="fl-post post-23 page type-page status-publish hentry"
                    id="fl-post-23"
                    itemScope="itemscope"
                    itemType="https://schema.org/CreativeWork"
                  >
                    <div className="fl-post-content clearfix" itemProp="text">
                      <div
                        className="fl-builder-content fl-builder-content-23 fl-builder-content-primary fl-builder-global-templates-locked"
                        data-post-id={23}
                      >
                        <div
                          className="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5c6ad162dfc4c"
                          data-node="5c6ad162dfc4c"
                        >
                          <div className="fl-row-content-wrap">
                            <div className="fl-row-content fl-row-fixed-width fl-node-content">
                              <div className="fl-col-group fl-node-5cba179113a63" data-node="5cba179113a63">
                                <div className="fl-col fl-node-5cba179113e32" data-node="5cba179113e32">
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-heading fl-node-5cba178914feb"
                                      data-node="5cba178914feb"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <h1 className="fl-heading">
                                          <span className="fl-heading-text">We build great web and mobile apps.</span>
                                        </h1>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="fl-col-group fl-node-5c6ad162df790" data-node="5c6ad162df790">
                                <div className="fl-col fl-node-5c6ad162dfaf9 fl-col-small" data-node="5c6ad162dfaf9">
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-video fl-node-5c6ad162dfbbf"
                                      data-node="5c6ad162dfbbf"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="fl-video fl-wp-video">
                                          <meta
                                            itemProp="url"
                                            content="/uploads/2019/02/Parsed.io-PushPress-Demonstration.mp4"
                                          />
                                          <meta itemProp="thumbnail" content />
                                          <div style={{ width: '100px' }} className="wp-video">
                                            <video
                                              muted
                                              className="wp-video-shortcode"
                                              id="video-23-1"
                                              width={100}
                                              height={100}
                                              loop={1}
                                              autoPlay={1}
                                              preload="metadata"
                                              controls="controls"
                                            >
                                              <source
                                                type="video/mp4"
                                                src="uploads/2019/02/Parsed.io-PushPress-Demonstration.mp4?_=1"
                                              />
                                              <a href="uploads/2019/02/Parsed.io-PushPress-Demonstration.mp4">
                                                /wp-content/uploads/2019/02/Parsed.io-PushPress-Demonstration.mp4
                                              </a>
                                            </video>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="fl-col fl-node-5c6ad162dfb61 fl-col-small" data-node="5c6ad162dfb61">
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-video fl-node-5c6ad162dfc07"
                                      data-node="5c6ad162dfc07"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="fl-video fl-wp-video">
                                          <meta
                                            itemProp="url"
                                            content="uploads/2019/02/Parsed.io-Erin-Demonstration.mp4"
                                          />
                                          <meta itemProp="thumbnail" content />
                                          <div style={{ width: '100px' }} className="wp-video">
                                            <video
                                              muted
                                              className="wp-video-shortcode"
                                              id="video-23-2"
                                              width={100}
                                              height={100}
                                              loop={1}
                                              autoPlay={1}
                                              preload="metadata"
                                              controls="controls"
                                            >
                                              <source
                                                type="video/mp4"
                                                src="uploads/2019/02/Parsed.io-Erin-Demonstration.mp4?_=2"
                                              />
                                              <a href="uploads/2019/02/Parsed.io-Erin-Demonstration.mp4">
                                                /wp-content/uploads/2019/02/Parsed.io-Erin-Demonstration.mp4
                                              </a>
                                            </video>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="fl-col-group fl-node-5c8252452e03e" data-node="5c8252452e03e">
                                <div className="fl-col fl-node-5c8252452e337 fl-col-has-cols" data-node="5c8252452e337">
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-col-group fl-node-5cba1950ea5fe fl-col-group-nested"
                                      data-node="5cba1950ea5fe"
                                    >
                                      <div className="fl-col fl-node-5cba1950ea8cb" data-node="5cba1950ea8cb">
                                        <div className="fl-col-content fl-node-content">
                                          <div
                                            className="fl-module fl-module-cta fl-node-5c8252173e0fb"
                                            data-node="5c8252173e0fb"
                                          >
                                            <div className="fl-module-content fl-node-content">
                                              <div className="fl-cta-wrap fl-cta-stacked fl-cta-center">
                                                <div className="fl-cta-text">
                                                  <h3 className="fl-cta-title">How can we help?</h3>
                                                  <div className="fl-cta-text-content" />
                                                </div>
                                                <div className="fl-cta-button">
                                                  <div className="fl-button-wrap fl-button-width-auto">
                                                    <a
                                                      href="#Tell-us-about-your-project"
                                                      target="_self"
                                                      className="fl-button"
                                                      role="button"
                                                    >
                                                      <span className="fl-button-text">Schedule a Call Today</span>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="what"
                          className="fl-row fl-row-full-width fl-row-bg-gradient fl-node-5cba1b98a297e"
                          data-node="5cba1b98a297e"
                        >
                          <div className="fl-row-content-wrap">
                            <div className="pp-row-separator pp-row-separator-top">
                              <svg
                                className="pp-big-triangle"
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                fill="#f5f5f5"
                                width="100%"
                                height={200}
                                viewBox="0 0 4.66666 0.333331"
                                preserveAspectRatio="none"
                              >
                                <path
                                  className="fil0"
                                  d="M-0 0.333331l4.66666 0 0 -3.93701e-006 -2.33333 0 -2.33333 0 0 3.93701e-006zm0 -0.333331l4.66666 0 0 0.166661 -4.66666 0 0 -0.166661zm4.66666 0.332618l0 -0.165953 -4.66666 0 0 0.165953 1.16162 -0.0826181 1.17171 -0.0833228 1.17171 0.0833228 1.16162 0.0826181z"
                                />
                              </svg>
                            </div>
                            <div className="fl-row-content fl-row-fixed-width fl-node-content">
                              <div className="fl-col-group fl-node-5cba1b98a33a6" data-node="5cba1b98a33a6">
                                <div className="fl-col fl-node-5cba1b98a33a9 fl-col-has-cols" data-node="5cba1b98a33a9">
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      id="build"
                                      className="fl-module fl-module-photo fl-node-5cba1b98a33aa cta-logo-1 overlay-1"
                                      data-node="5cba1b98a33aa"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div
                                          className="fl-photo fl-photo-align-center"
                                          itemScope
                                          itemType="https://schema.org/ImageObject"
                                        >
                                          <div className="fl-photo-content fl-photo-img-png">
                                            <img
                                              className="fl-photo-img wp-image-331 size-full"
                                              src="uploads/2018/05/Parsed_LevenimMT_Final_Stacked.png"
                                              alt="Parsed_LevenimMT_Final_Stacked"
                                              itemProp="image"
                                              height={1160}
                                              width={1110}
                                              title="Parsed_LevenimMT_Final_Stacked"
                                              srcSet="
                                            uploads/2018/05/Parsed_LevenimMT_Final_Stacked.png          1110w,
                                            uploads/2018/05/Parsed_LevenimMT_Final_Stacked-287x300.png   287w,
                                            uploads/2018/05/Parsed_LevenimMT_Final_Stacked-768x803.png   768w,
                                            uploads/2018/05/Parsed_LevenimMT_Final_Stacked-980x1024.png  980w
                                          "
                                              sizes="(max-width: 1110px) 100vw, 1110px"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="fl-module fl-module-heading fl-node-5cba1b98a33ab"
                                      data-node="5cba1b98a33ab"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <h2 className="fl-heading">
                                          <span className="fl-heading-text">
                                            We Build React Web and React Native Mobile Apps.
                                          </span>
                                        </h2>
                                      </div>
                                    </div>
                                    <div
                                      className="fl-col-group fl-node-5cba1b98a33ac fl-col-group-nested"
                                      data-node="5cba1b98a33ac"
                                    >
                                      <div
                                        className="fl-col fl-node-5cba1b98a33ad fl-col-small"
                                        data-node="5cba1b98a33ad"
                                      >
                                        <div className="fl-col-content fl-node-content" />
                                      </div>
                                      <div className="fl-col fl-node-5cba1b98a33b1" data-node="5cba1b98a33b1">
                                        <div className="fl-col-content fl-node-content">
                                          <div
                                            className="fl-module fl-module-rich-text fl-node-5cba1b98a33b2 center-text"
                                            data-node="5cba1b98a33b2"
                                          >
                                            <div className="fl-module-content fl-node-content">
                                              <div className="fl-rich-text">
                                                <p>
                                                  At Parsed, we specialize as a React and React Native development
                                                  company.
                                                </p>
                                                <p>
                                                  Before React and React Native, efficient cross-platform development
                                                  required compromises. Today, we can create the responsive, flexible,
                                                  native mobile app you need – no compromises on performance.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="fl-col fl-node-5cba1b98a33b3 fl-col-small"
                                        data-node="5cba1b98a33b3"
                                      >
                                        <div className="fl-col-content fl-node-content" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="fl-row fl-row-full-width fl-row-bg-color fl-node-5c6ad1738fcb9"
                          data-node="5c6ad1738fcb9"
                        >
                          <div className="fl-row-content-wrap">
                            <div className="fl-row-content fl-row-fixed-width fl-node-content">
                              <div className="fl-col-group fl-node-5c6ad17390035" data-node="5c6ad17390035">
                                <div className="fl-col fl-node-5c6ad173900c7" data-node="5c6ad173900c7">
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-heading fl-node-5c6ad1739010a"
                                      data-node="5c6ad1739010a"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <h3 className="fl-heading">
                                          <span className="fl-heading-text">A few of our customers</span>
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5c6acf3f330a8"
                          data-node="5c6acf3f330a8"
                        >
                          <div className="fl-row-content-wrap">
                            <div className="fl-row-content fl-row-fixed-width fl-node-content">
                              <div className="fl-col-group fl-node-5c6acf3f48458" data-node="5c6acf3f48458">
                                <div className="fl-col fl-node-5c6acf3f4878b" data-node="5c6acf3f4878b">
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-pp-logos-grid fl-node-5db757bc3dec6"
                                      data-node="5db757bc3dec6"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="pp-logos-content clearfix">
                                          <div className="pp-logos-wrapper clearfix">
                                            <div className="pp-logo pp-logo-0">
                                              <div className="pp-logo-inner">
                                                <div className="pp-logo-inner-wrap">
                                                  <div className="logo-image-wrapper">
                                                    <img
                                                      className="logo-image"
                                                      src="uploads/2019/03/Western-Digital-Logo.png"
                                                      alt="uploads/2019/03/Western-Digital-Logo.png"
                                                      data-no-lazy={1}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="pp-logo pp-logo-1">
                                              <div className="pp-logo-inner">
                                                <div className="pp-logo-inner-wrap">
                                                  <div className="logo-image-wrapper">
                                                    <img
                                                      className="logo-image"
                                                      src="uploads/2019/09/Cresco-Labs.jpg"
                                                      alt="uploads/2019/09/Cresco-Labs.jpg"
                                                      data-no-lazy={1}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="pp-logo pp-logo-2">
                                              <div className="pp-logo-inner">
                                                <div className="pp-logo-inner-wrap">
                                                  <div className="logo-image-wrapper">
                                                    <img
                                                      className="logo-image"
                                                      src="uploads/2019/04/Serverless-Logo.png"
                                                      alt="uploads/2019/04/Serverless-Logo.png"
                                                      data-no-lazy={1}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="pp-logo pp-logo-3">
                                              <div className="pp-logo-inner">
                                                <div className="pp-logo-inner-wrap">
                                                  <div className="logo-image-wrapper">
                                                    <img
                                                      className="logo-image"
                                                      src="uploads/2019/02/Erin-Logo.png"
                                                      alt="uploads/2019/02/Erin-Logo.png"
                                                      data-no-lazy={1}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="pp-logo pp-logo-4">
                                              <div className="pp-logo-inner">
                                                <div className="pp-logo-inner-wrap">
                                                  <div className="logo-image-wrapper">
                                                    <img
                                                      className="logo-image"
                                                      src="uploads/2019/02/PushPress-Logo2.png"
                                                      alt="uploads/2019/02/PushPress-Logo2.png"
                                                      data-no-lazy={1}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="pp-logo pp-logo-5">
                                              <div className="pp-logo-inner">
                                                <div className="pp-logo-inner-wrap">
                                                  <div className="logo-image-wrapper">
                                                    <img
                                                      className="logo-image"
                                                      src="uploads/2019/02/Breadware-Logo.png"
                                                      alt="uploads/2019/02/Breadware-Logo.png"
                                                      data-no-lazy={1}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="pp-logo pp-logo-6">
                                              <div className="pp-logo-inner">
                                                <div className="pp-logo-inner-wrap">
                                                  <div className="logo-image-wrapper">
                                                    <img
                                                      className="logo-image"
                                                      src="uploads/2019/05/catholicmatch-promo-codes-coupons.png"
                                                      alt="uploads/2019/05/catholicmatch-promo-codes-coupons.png"
                                                      data-no-lazy={1}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="pp-logo pp-logo-7">
                                              <div className="pp-logo-inner">
                                                <div className="pp-logo-inner-wrap">
                                                  <div className="logo-image-wrapper">
                                                    <img
                                                      className="logo-image"
                                                      src="uploads/2019/02/Futuri-Logo.png"
                                                      alt="uploads/2019/02/Futuri-Logo.png"
                                                      data-no-lazy={1}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="pp-logo pp-logo-8">
                                              <div className="pp-logo-inner">
                                                <div className="pp-logo-inner-wrap">
                                                  <div className="logo-image-wrapper">
                                                    <img
                                                      className="logo-image"
                                                      src="uploads/2019/02/Labco-Logo.png"
                                                      alt="uploads/2019/02/Labco-Logo.png"
                                                      data-no-lazy={1}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="pp-logo pp-logo-9">
                                              <div className="pp-logo-inner">
                                                <div className="pp-logo-inner-wrap">
                                                  <div className="logo-image-wrapper">
                                                    <img
                                                      className="logo-image"
                                                      src="uploads/2018/05/isl-01.png"
                                                      alt="uploads/2018/05/isl-01.png"
                                                      data-no-lazy={1}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="pp-logo pp-logo-10">
                                              <div className="pp-logo-inner">
                                                <div className="pp-logo-inner-wrap">
                                                  <div className="logo-image-wrapper">
                                                    <img
                                                      className="logo-image"
                                                      src="uploads/2019/09/smartsheet-logo-1.png"
                                                      alt="uploads/2019/09/smartsheet-logo-1.png"
                                                      data-no-lazy={1}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="pp-logo pp-logo-11">
                                              <div className="pp-logo-inner">
                                                <div className="pp-logo-inner-wrap">
                                                  <div className="logo-image-wrapper">
                                                    <img
                                                      className="logo-image"
                                                      src="uploads/2018/05/djcity-01.png"
                                                      alt="uploads/2018/05/djcity-01.png"
                                                      data-no-lazy={1}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="pp-logo pp-logo-12">
                                              <div className="pp-logo-inner">
                                                <div className="pp-logo-inner-wrap">
                                                  <div className="logo-image-wrapper">
                                                    <img
                                                      className="logo-image"
                                                      src="uploads/2019/02/SettleIn-Logo.jpg"
                                                      alt="uploads/2019/02/SettleIn-Logo.jpg"
                                                      data-no-lazy={1}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="pp-logo pp-logo-13">
                                              <div className="pp-logo-inner">
                                                <div className="pp-logo-inner-wrap">
                                                  <div className="logo-image-wrapper">
                                                    <img
                                                      className="logo-image"
                                                      src="uploads/2019/02/Blue-Sky-Therapy-Logo.png"
                                                      alt="uploads/2019/02/Blue-Sky-Therapy-Logo.png"
                                                      data-no-lazy={1}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="logo-slider-next" />
                                          <div className="logo-slider-prev" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="fl-col-group fl-node-5c6ad0e175842" data-node="5c6ad0e175842">
                                <div className="fl-col fl-node-5c6ad0e176273" data-node="5c6ad0e176273">
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-heading fl-node-5c6ad0e17489e"
                                      data-node="5c6ad0e17489e"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <h3 className="fl-heading">
                                          <span className="fl-heading-text">Here's what they say about us:</span>
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5c6ad0f9adfb9"
                          data-node="5c6ad0f9adfb9"
                        >
                          <div className="fl-row-content-wrap">
                            <div className="fl-row-content fl-row-fixed-width fl-node-content">
                              <div className="fl-col-group fl-node-5c6ad0f9ade63" data-node="5c6ad0f9ade63">
                                <div className="fl-col fl-node-5c6ad0f9adee2" data-node="5c6ad0f9adee2">
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-testimonials fl-node-5c6ad0f9adf68"
                                      data-node="5c6ad0f9adf68"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="fl-testimonials-wrap wide">
                                          <div className="fl-testimonials">
                                            <div className="fl-testimonial">
                                              <p>
                                                <img
                                                  className="size-medium wp-image-173 alignleft"
                                                  src="uploads/2019/02/PushPress-300x86.png"
                                                  alt=""
                                                  width={300}
                                                  height={86}
                                                  srcSet="
                                                uploads/2019/02/PushPress-300x86.png 300w,
                                                uploads/2019/02/PushPress.png        472w
                                              "
                                                  sizes="(max-width: 300px) 100vw, 300px"
                                                />
                                                "We've found with other projects and developers, that costs often spin
                                                out of control. We were impressed with how Parsed stayed on budget."
                                              </p>
                                              <p>Chris McConachie, Co-Founder &amp; Director of Product</p>
                                            </div>
                                            <div className="fl-testimonial">
                                              <p>
                                                <img
                                                  className="size-full wp-image-43 alignleft"
                                                  src="uploads/2018/05/isl-02.png"
                                                  alt=""
                                                  width={255}
                                                  height={169}
                                                />
                                                "You guys have been the best development partners our team has ever
                                                collaborated with. We appreciated your ability to integrate seamlessly
                                                with our team and process - and your creative approach to solutions."
                                              </p>
                                            </div>
                                            <div className="fl-testimonial">
                                              <p>
                                                <img
                                                  className="size-medium wp-image-50 alignleft"
                                                  src="uploads/2018/05/djcity-04-300x53.png"
                                                  alt=""
                                                  width={300}
                                                  height={53}
                                                  srcSet="
                                                uploads/2018/05/djcity-04-300x53.png 300w,
                                                uploads/2018/05/djcity-04.png        340w
                                              "
                                                  sizes="(max-width: 300px) 100vw, 300px"
                                                />
                                                "The Parsed team is amazing. If you need a mobile presence, then look no
                                                further. They have you covered."
                                              </p>
                                              <p>Patrick Santora, CTO</p>
                                            </div>
                                          </div>
                                          <div className="fl-slider-next" role="button" aria-pressed="false" />
                                          <div className="fl-slider-prev" role="button" aria-pressed="false" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="fl-col-group fl-node-5c82526b9e73f" data-node="5c82526b9e73f">
                                <div className="fl-col fl-node-5c82526b9ea32" data-node="5c82526b9ea32">
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-cta fl-node-5c825290d3558"
                                      data-node="5c825290d3558"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="fl-cta-wrap fl-cta-stacked fl-cta-center">
                                          <div className="fl-cta-text">
                                            <h3 className="fl-cta-title">How can we help?</h3>
                                            <div className="fl-cta-text-content" />
                                          </div>
                                          <div className="fl-cta-button">
                                            <div className="fl-button-wrap fl-button-width-auto">
                                              <a
                                                href="#Tell-us-about-your-project"
                                                target="_self"
                                                className="fl-button"
                                                role="button"
                                              >
                                                <span className="fl-button-text">Schedule a Call Today</span>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="fl-row fl-row-full-width fl-row-bg-color fl-node-5cba1c28c273c"
                          data-node="5cba1c28c273c"
                        >
                          <div className="fl-row-content-wrap">
                            <div className="fl-row-content fl-row-fixed-width fl-node-content">
                              <div className="fl-col-group fl-node-5cba1c28c29b3" data-node="5cba1c28c29b3">
                                <div className="fl-col fl-node-5cba1c28c29b7" data-node="5cba1c28c29b7">
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-heading fl-node-5cba1c28c29b8"
                                      data-node="5cba1c28c29b8"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <h3 className="fl-heading">
                                          <span className="fl-heading-text">Our Promise</span>
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="what"
                          className="fl-row fl-row-full-width fl-row-bg-gradient fl-node-5cba1b751a0bb"
                          data-node="5cba1b751a0bb"
                        >
                          <div className="fl-row-content-wrap">
                            <div className="fl-row-content fl-row-fixed-width fl-node-content">
                              <div className="fl-col-group fl-node-5cba1b751aa30" data-node="5cba1b751aa30">
                                <div className="fl-col fl-node-5cba1b751aa31 fl-col-has-cols" data-node="5cba1b751aa31">
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-col-group fl-node-5cba1b751aa32 fl-col-group-nested"
                                      data-node="5cba1b751aa32"
                                    >
                                      <div
                                        className="fl-col fl-node-5cba1b751aa33 fl-col-small"
                                        data-node="5cba1b751aa33"
                                      >
                                        <div className="fl-col-content fl-node-content">
                                          <div
                                            className="fl-module fl-module-callout fl-node-5cba1b751aa34"
                                            data-node="5cba1b751aa34"
                                          >
                                            <div className="fl-module-content fl-node-content">
                                              <div className="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                                                <div className="fl-callout-content">
                                                  <h3 className="fl-callout-title">
                                                    <span className="fl-icon">
                                                      <i className="far fa-edit" aria-hidden="true" />
                                                    </span>
                                                    <div
                                                      id="fl-icon-text-"
                                                      className="fl-icon-text fl-icon-text-wrap fl-icon-text-empty"
                                                    />
                                                    <span className="fl-callout-title-text">
                                                      We build user-centered web and mobile apps
                                                    </span>
                                                  </h3>
                                                  <div className="fl-callout-text-wrap">
                                                    <div className="fl-callout-text">
                                                      <p>
                                                        You know your users best, and we get that. With your
                                                        requirements in hand,
                                                        <a href="/iphone-ios-app-development-services-company/">
                                                          we build iOS
                                                        </a>
                                                        and Android apps that drive business results and make users
                                                        swoon.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="fl-col fl-node-5cba1b751aa35 fl-col-small"
                                        data-node="5cba1b751aa35"
                                      >
                                        <div className="fl-col-content fl-node-content">
                                          <div
                                            className="fl-module fl-module-callout fl-node-5cba1b751aa36"
                                            data-node="5cba1b751aa36"
                                          >
                                            <div className="fl-module-content fl-node-content">
                                              <div className="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                                                <div className="fl-callout-content">
                                                  <h3 className="fl-callout-title">
                                                    <span className="fl-icon">
                                                      <i className="far fa-bookmark" aria-hidden="true" />
                                                    </span>
                                                    <div
                                                      id="fl-icon-text-"
                                                      className="fl-icon-text fl-icon-text-wrap fl-icon-text-empty"
                                                    />
                                                    <span className="fl-callout-title-text">
                                                      We provide straight forward pricing
                                                    </span>
                                                  </h3>
                                                  <div className="fl-callout-text-wrap">
                                                    <div className="fl-callout-text">
                                                      <p>
                                                        As specialists, we do great work fast. We also offer flat-rate
                                                        pricing, so you’re never in the dark about how much something
                                                        costs.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="fl-col-group fl-node-5cba1b751aa37 fl-col-group-nested"
                                      data-node="5cba1b751aa37"
                                    >
                                      <div
                                        className="fl-col fl-node-5cba1b751aa38 fl-col-small"
                                        data-node="5cba1b751aa38"
                                      >
                                        <div className="fl-col-content fl-node-content">
                                          <div
                                            className="fl-module fl-module-callout fl-node-5cba1b751aa39"
                                            data-node="5cba1b751aa39"
                                          >
                                            <div className="fl-module-content fl-node-content">
                                              <div className="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                                                <div className="fl-callout-content">
                                                  <h3 className="fl-callout-title">
                                                    <span className="fl-icon">
                                                      <i className="far fa-clone" aria-hidden="true" />
                                                    </span>
                                                    <div
                                                      id="fl-icon-text-"
                                                      className="fl-icon-text fl-icon-text-wrap fl-icon-text-empty"
                                                    />
                                                    <span className="fl-callout-title-text">
                                                      We provide transparency in a collaborative environment
                                                    </span>
                                                  </h3>
                                                  <div className="fl-callout-text-wrap">
                                                    <div className="fl-callout-text">
                                                      <p>
                                                        We actively share our progress with you, so you can test new
                                                        features right away and send us your feedback.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="fl-col fl-node-5cba1b751aa3a fl-col-small"
                                        data-node="5cba1b751aa3a"
                                      >
                                        <div className="fl-col-content fl-node-content">
                                          <div
                                            className="fl-module fl-module-callout fl-node-5cba1b751aa3b"
                                            data-node="5cba1b751aa3b"
                                          >
                                            <div className="fl-module-content fl-node-content">
                                              <div className="fl-callout fl-callout-left fl-callout-has-icon fl-callout-icon-left-title">
                                                <div className="fl-callout-content">
                                                  <h3 className="fl-callout-title">
                                                    <span className="fl-icon">
                                                      <i className="far fa-heart" aria-hidden="true" />
                                                    </span>
                                                    <div
                                                      id="fl-icon-text-"
                                                      className="fl-icon-text fl-icon-text-wrap fl-icon-text-empty"
                                                    />
                                                    <span className="fl-callout-title-text">
                                                      We provide value as your development partner
                                                    </span>
                                                  </h3>
                                                  <div className="fl-callout-text-wrap">
                                                    <div className="fl-callout-text">
                                                      <p>
                                                        As a distributed team of React Native
                                                        <a href="/how-to-hire-app-developers/">app developers</a>, we
                                                        maximize talent and minimize overhead. We pass that savings to
                                                        you!
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="fl-col-group fl-node-5cba1b751aa3c" data-node="5cba1b751aa3c">
                                <div className="fl-col fl-node-5cba1b751aa3d" data-node="5cba1b751aa3d">
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-cta fl-node-5cba1b751aa3e"
                                      data-node="5cba1b751aa3e"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="fl-cta-wrap fl-cta-stacked fl-cta-center">
                                          <div className="fl-cta-text">
                                            <h3 className="fl-cta-title">How can we help?</h3>
                                            <div className="fl-cta-text-content" />
                                          </div>
                                          <div className="fl-cta-button">
                                            <div className="fl-button-wrap fl-button-width-auto">
                                              <a
                                                href="#Tell-us-about-your-project"
                                                target="_self"
                                                className="fl-button"
                                                role="button"
                                              >
                                                <span className="fl-button-text">Schedule a Call Today</span>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="who"
                          className="fl-row fl-row-full-width fl-row-bg-photo fl-node-5aee7f322f332 fl-row-bg-overlay"
                          data-node="5aee7f322f332"
                        >
                          <div className="fl-row-content-wrap">
                            <div className="fl-row-content fl-row-fixed-width fl-node-content">
                              <div
                                className="fl-col-group fl-node-5aee7f323802f fl-col-group-equal-height fl-col-group-align-center fl-col-group-custom-width"
                                data-node="5aee7f323802f"
                              >
                                <div className="fl-col fl-node-5aee7f3238313 fl-col-small" data-node="5aee7f3238313">
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-heading fl-node-5aee7f45c0c5b"
                                      data-node="5aee7f45c0c5b"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <h2 className="fl-heading">
                                          <span className="fl-heading-text">
                                            We're a React web and React Native mobile app development company with a
                                            national footprint.
                                          </span>
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="fl-col fl-node-5aee7f3238373" data-node="5aee7f3238373">
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-rich-text fl-node-5aee7f6db534a font-size-20"
                                      data-node="5aee7f6db534a"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="fl-rich-text">
                                          <p>
                                            Experienced, specialized, and distributed. That’s Parsed. Oh, and we’re also
                                            obsessed with creating web and mobile apps that are intuitive, beautiful,
                                            and valuable to our clients and their users.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="fl-col-group fl-node-5aee80abc4ae2 fl-col-group-equal-height fl-col-group-align-top fl-col-group-custom-width"
                                data-node="5aee80abc4ae2"
                              >
                                <div className="fl-col fl-node-5aee80abc4e70 fl-col-small" data-node="5aee80abc4e70">
                                  <div className="fl-col-content fl-node-content" />
                                </div>
                                <div className="fl-col fl-node-5aee80abc4ece" data-node="5aee80abc4ece">
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-pp-infolist fl-node-5aee80bddc139"
                                      data-node="5aee80bddc139"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="pp-infolist-wrap">
                                          <div className="pp-infolist layout-1">
                                            <ul className="layout-1-wrapper">
                                              <li className="pp-list-item pp-list-item-0">
                                                <div className="pp-icon-wrapper animated none">
                                                  <div className="pp-infolist-icon">
                                                    <div className="pp-infolist-icon-inner">
                                                      <span className="pp-icon far fa-check-square" />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="pp-heading-wrapper">
                                                  <div className="pp-infolist-title">
                                                    <h3 className="pp-infolist-title-text">
                                                      We’re a US based distributed team.
                                                    </h3>
                                                  </div>
                                                  <div className="pp-infolist-description">
                                                    <p>
                                                      No matter where you are in North America, Parsed is on your
                                                      schedule. Instead of having the best office, we insist on having
                                                      the best people. We’re a software company headquartered in
                                                      Pittsburgh and located everywhere.
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="pp-list-connector" />
                                              </li>
                                              <li className="pp-list-item pp-list-item-1">
                                                <div className="pp-icon-wrapper animated none">
                                                  <div className="pp-infolist-icon">
                                                    <div className="pp-infolist-icon-inner">
                                                      <span className="pp-icon far fa-check-square" />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="pp-heading-wrapper">
                                                  <div className="pp-infolist-title">
                                                    <h3 className="pp-infolist-title-text">
                                                      We’re React and React Native evangelists
                                                    </h3>
                                                  </div>
                                                  <div className="pp-infolist-description">
                                                    <p>
                                                      It’s why we run ReactPgh, a meetup that spreads the joy of React
                                                      Native to developers across Pittsburgh. Why all the love for React
                                                      Native? Because there’s simply no better way to
                                                      <a href="/cross-platform-mobile-development/">
                                                        develop cross-platform mobile applications
                                                      </a>
                                                      .
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="pp-list-connector" />
                                              </li>
                                              <li className="pp-list-item pp-list-item-2">
                                                <div className="pp-icon-wrapper animated none">
                                                  <div className="pp-infolist-icon">
                                                    <div className="pp-infolist-icon-inner">
                                                      <span className="pp-icon far fa-check-square" />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="pp-heading-wrapper">
                                                  <div className="pp-infolist-title">
                                                    <h3 className="pp-infolist-title-text">
                                                      We’re native mobile experts.
                                                    </h3>
                                                  </div>
                                                  <div className="pp-infolist-description">
                                                    <p>
                                                      At Parsed, we’re not just React Native developers. We’re also
                                                      experts in native iOS and Android apps. Our native app expertise
                                                      feeds React Native development capabilities and vice versa.
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="pp-list-connector" />
                                              </li>
                                              <li className="pp-list-item pp-list-item-3">
                                                <div className="pp-icon-wrapper animated none">
                                                  <div className="pp-infolist-icon">
                                                    <div className="pp-infolist-icon-inner">
                                                      <span className="pp-icon far fa-check-square" />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="pp-heading-wrapper">
                                                  <div className="pp-infolist-title">
                                                    <h3 className="pp-infolist-title-text">We’re in this for you.</h3>
                                                  </div>
                                                  <div className="pp-infolist-description">
                                                    <p>
                                                      The way we see it, building mobile apps isn’t about geeking out on
                                                      the technology or building every conceivable feature just ‘cuz.
                                                      It’s about creating your vision, helping your users, and
                                                      delivering maximum value to you.
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="pp-list-connector" />
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="fl-row fl-row-full-width fl-row-bg-color fl-node-5c6acfc463a01"
                          data-node="5c6acfc463a01"
                        >
                          <div className="fl-row-content-wrap">
                            <div className="fl-row-content fl-row-fixed-width fl-node-content">
                              <div className="fl-col-group fl-node-5c6acfc46391d" data-node="5c6acfc46391d">
                                <div className="fl-col fl-node-5c6acfc46396e" data-node="5c6acfc46396e">
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-heading fl-node-5c6acfc4639bc"
                                      data-node="5c6acfc4639bc"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <h3 className="fl-heading">
                                          <span className="fl-heading-text">We want to work with you.</span>
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="fl-row fl-row-fixed-width fl-row-bg-color fl-node-5c6ad07575ac2"
                          data-node="5c6ad07575ac2"
                        >
                          <div className="fl-row-content-wrap">
                            <div className="fl-row-content fl-row-fixed-width fl-node-content">
                              <div className="fl-col-group fl-node-5c6ad0757580c" data-node="5c6ad0757580c">
                                <div className="fl-col fl-node-5c6ad07575833 fl-col-has-cols" data-node="5c6ad07575833">
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-col-group fl-node-5ce69aa85630e fl-col-group-nested"
                                      data-node="5ce69aa85630e"
                                    >
                                      <div className="fl-col fl-node-5ce69aa8566c4" data-node="5ce69aa8566c4">
                                        <div className="fl-col-content fl-node-content">
                                          <div
                                            id="Tell-us-about-your-project"
                                            className="fl-module fl-module-heading fl-node-5c6ad07575927"
                                            data-node="5c6ad07575927"
                                          >
                                            <div className="fl-module-content fl-node-content">
                                              <h3 className="fl-heading">
                                                <span className="fl-heading-text">
                                                  Tell us about your project, and how we can help.
                                                </span>
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="fl-col fl-node-5ce69aa8566cb fl-col-small"
                                        data-node="5ce69aa8566cb"
                                      >
                                        <div className="fl-col-content fl-node-content">
                                          <div
                                            className="fl-module fl-module-photo fl-node-5ce69ac143bab"
                                            data-node="5ce69ac143bab"
                                          >
                                            <div className="fl-module-content fl-node-content">
                                              <div
                                                className="fl-photo fl-photo-align-left"
                                                itemScope
                                                itemType="https://schema.org/ImageObject"
                                              >
                                                <div className="fl-photo-content fl-photo-img-png">
                                                  <img
                                                    className="fl-photo-img wp-image-331 size-thumbnail"
                                                    src="uploads/2018/05/Parsed_LevenimMT_Final_Stacked-150x150.png"
                                                    alt="Parsed_LevenimMT_Final_Stacked"
                                                    itemProp="image"
                                                    height={150}
                                                    width={150}
                                                    title="Parsed_LevenimMT_Final_Stacked"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="fl-module fl-module-photo fl-node-5ce69a95ad0c8"
                                      data-node="5ce69a95ad0c8"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div
                                          className="fl-photo fl-photo-align-center"
                                          itemScope
                                          itemType="https://schema.org/ImageObject"
                                        >
                                          <div className="fl-photo-content fl-photo-img-png">
                                            <img
                                              className="fl-photo-img"
                                              src="plugins/bb-plugin/img/pixel.png"
                                              alt=""
                                              itemProp="image"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="fl-module fl-module-separator fl-node-5c6ad07575a6b"
                                      data-node="5c6ad07575a6b"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="fl-separator" />
                                      </div>
                                    </div>
                                    <div
                                      className="fl-module fl-module-widget fl-node-5c952a9bc55e3"
                                      data-node="5c952a9bc55e3"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="fl-widget">
                                          <div className="widget wpforms-widget">
                                            <div className="wpforms-container wpforms-container-full" id="wpforms-27">
                                              <form
                                                name="contact"
                                                method="post"
                                                action="/thanks/"
                                                data-netlify="true"
                                                data-netlify-honeypot="bot-field"
                                                onSubmit={(e) => this.handleSubmit(e)}
                                              >
                                                <input type="hidden" name="form-name" value="contact" />
                                                <p hidden>
                                                  <label>
                                                    Don’t fill this out:{' '}
                                                    <input name="bot-field" onChange={(e) => this.handleChange(e)} />
                                                  </label>
                                                </p>
                                                <p>
                                                  <label>
                                                    Your name:
                                                    <br />
                                                    <input
                                                      type="text"
                                                      name="name"
                                                      onChange={(e) => this.handleChange(e)}
                                                    />
                                                  </label>
                                                </p>
                                                <p>
                                                  <label>
                                                    Your email:
                                                    <br />
                                                    <input
                                                      type="email"
                                                      name="email"
                                                      onChange={(e) => this.handleChange(e)}
                                                    />
                                                  </label>
                                                </p>
                                                <p>
                                                  <label>
                                                    Message:
                                                    <br />
                                                    <textarea name="message" onChange={(e) => this.handleChange(e)} />
                                                  </label>
                                                </p>
                                                <p>
                                                  <button type="submit">Send</button>
                                                </p>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="fl-col-group fl-node-5c90314f1c854 fl-col-group-nested"
                                      data-node="5c90314f1c854"
                                    >
                                      <div className="fl-col fl-node-5c90314f1cb91" data-node="5c90314f1cb91">
                                        <div className="fl-col-content fl-node-content">
                                          <div
                                            className="fl-module fl-module-html fl-node-5c8fdff0a8040"
                                            data-node="5c8fdff0a8040"
                                          >
                                            <div className="fl-module-content fl-node-content">
                                              <div className="fl-html" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="fl-module fl-module-heading fl-node-5c6ad07575972"
                                      data-node="5c6ad07575972"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <h3 className="fl-heading">
                                          <span className="fl-heading-text">Or, drop us an email at hi@parsed.io</span>
                                        </h3>
                                      </div>
                                    </div>
                                    <div
                                      className="fl-module fl-module-heading fl-node-5c6ad0757588c"
                                      data-node="5c6ad0757588c"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <h3 className="fl-heading">
                                          <span className="fl-heading-text" />
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <Footer config={config} />
      </div>
    );
  }
}

export default Home;
